





























































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },

  setup(_, { root }) {
    const { getBase64, goBack } = useMisc({ root });
    const form = ref<any>(null);

    const model = reactive<{
      template: any;
      name: string;
      title: string;
      editor: any;
      files: Array<any>;
      attachCertificate: boolean;
      attachTextToPdfMessage: boolean;
      textToPdfMessageContent: string | null;
    }>({
      template: "",
      name: "",
      title: "",
      editor: {
        chunks: "",
        design: "",
        html: "",
        options: "",
      },
      files: [],
      attachCertificate: false,
      attachTextToPdfMessage: false,
      textToPdfMessageContent: null,
    });

    const state = reactive({
      templates: [],
      dialog: false,
      success: false,
      error: false as boolean | number,
      loading: false,
      loaded: false,
      editorLoaded: true,
      eventName: "",
      valid: false,
    });

    const fetchTemplates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("message-template", { params: { type: "other" } })
        .then(({ data: { messageTemplates } }) => {
          state.templates = messageTemplates;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchTemplates);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/${model.template}`)
        .then(({ data: { messageTemplate } }) => {
          model.editor.design = messageTemplate.design || undefined;
          model.title = messageTemplate.title || undefined;
          state.editorLoaded = true;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    watch(() => model.template, fetchData);
    watch(
      () => model.template,
      () => {
        if (state.editorLoaded) {
          state.editorLoaded = false;
          fetchData();
        }
      }
    );

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventName = event.name;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.templates = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchEvent);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 405:
          return `${root.$tc("event.panel.modules.alertModule.add.error405")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start fetch single message

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const files: any[] = [];

      if (model.files && model.files.length) {
        for (const file of model.files) {
          const fileName = file.name;
          const content = await getBase64(file);
          files.push({
            filename: fileName,
            content,
          });
        }
      }

      const data = {
        template: model.editor.html || undefined,
        design: model.editor.design || undefined,
        name: model.title || undefined,
        title: model.title || undefined,
        attachments: files,
        attachCertificate: model.attachCertificate,
        attachTextToPdfMessage: model.attachTextToPdfMessage,
        textToPdfMessageContent: model.textToPdfMessageContent,
      };

      state.loading = true;

      axiosInstance
        .post(`message-template/${root.$route.params.id}/other`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          model.editor.html = "";
          model.editor.design = "";
          model.name = "";
          model.title = "";
          model.files = [];
          model.attachCertificate = false;
          model.attachTextToPdfMessage = false;
          model.textToPdfMessageContent = null;

          state.editorLoaded = false;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.abstract.config.mails.save"),
          });
          setTimeout(() => {
            goBack("panel.event.view.alertModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => {
          state.editorLoaded = true;
          state.loading = false;
        });
    };

    watch(
      () => model.title,
      () => {
        if (model.title?.includes("{{ event.name }}")) {
          model.title = model.title.replace(
            "{{ event.name }}",
            state.eventName
          );
        }
      }
    );

    const saveAsTemplate = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        template: model.editor.html || undefined,
        design: model.editor.design || undefined,
        name: model.name,
        title: model.title || undefined,
        type: "other",
      };

      state.loading = true;

      axiosInstance
        .post("message-template", data)
        .then(() => {
          state.success = true;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("panel.event.abstract.config.mails.save"),
          });
          state.dialog = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const rules = {
      required: [(v: string) => !!v || root.$t("layout.misc.required")],
      files: [
        (v: any) =>
          v.length <= 3 || root.$tc("panel.event.add.alert.toManyFiles"),
      ],
    };

    return {
      form,
      model,
      state,
      onSubmit,
      getErrorMessage,
      saveAsTemplate,
      rules,
    };
  },
});
